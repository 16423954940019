import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/offers/:offerId/parcels/:parcelId',
    component: () => import('../pages/ParcelDetailsPage.vue'),
    props: true,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: 'parcelDetailsBaseDataTab',
        path: '',
        component: () => import('../pages/ParcelDetailsBaseDataPage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'proPlantaTab',
        path: 'proplanta',
        component: () => import('../pages/ProPlantaPage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/offers/:offerId/parcels/:parcelId/maximap',
    component: () => import('../pages/ParcelMaxiMapDetailsPage.vue'),
    props: true,
    meta: {
      requiresAuth: true
    },

    children: [
      {
        name: 'parcelMaxiMapDetailsBaseDataTab',
        path: '',
        component: () => import('../pages/ParcelMaxiMapDetailsBaseDataPage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
];

export default routes;
