import type { FormElement } from '@common/lib/modules/form';
import { FormElementPlugin } from '@common/lib/modules/form';

export class ParcelYieldOnlyPlugin extends FormElementPlugin {
  public constructor() {
    super();
    this._name = 'parcelYieldOnlyCrop';
  }

  public override onInput(element: FormElement<any>, value: any) {
    const yieldGroup = element.parent as FormElement;

    const fieldsToHide = ['value', 'sowingDate', 'harvestDate', 'comment'];
    fieldsToHide.forEach((fieldName) => this.setFieldVisiblity(fieldName, !value, yieldGroup));
  }

  private setFieldVisiblity(fieldName: string, visible: boolean, yieldGroup: FormElement) {
    const field = yieldGroup.getChild(fieldName);
    if (field) {
      field.visible = visible;
      field.value = undefined;
    }
  }
}
