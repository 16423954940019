import { FormValidator, type FormValidatorContext, type FormValidatorConfig, type FormValidatorSettings, isEmpty } from '@common/index';
import { toRaw } from 'vue';

export interface PlantingDateValidatorSettings extends FormValidatorSettings {
  otherFieldName: string;
  allowEqual: boolean;
}

export type PlantingDateValidatorConfig = FormValidatorConfig<PlantingDateValidatorSettings>;

export class PlantingDateValidator extends FormValidator<PlantingDateValidatorSettings> {
  constructor() {
    super();
    this._name = 'plantingDate';
    this._settings = {
      invalidMessage: 'parcel.details.validator.plantingDate.invalidMessage',
      needTranslate: true,
      otherFieldName: 'harvestDate',
      allowEqual: false
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    if (isEmpty(value)) {
      this.reset();
      return;
    }

    context.settings = { triggerRelatedField: false };
    const values = toRaw(context.element.parent?.value);
    const otherValue = values[this.settings.otherFieldName];
    if (otherValue) {
      const date = new Date(value);
      const otherDate = new Date(otherValue);
      this._valid = this.settings.allowEqual ? date <= otherDate : date < otherDate;
      if (!this._valid) {
        this.addMessage(this.settings.invalidMessage);
      }
    }
  }
}
