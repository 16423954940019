<script lang="ts" setup>
import { computed, onMounted, onUpdated, type PropType, ref, type WritableComputedRef } from 'vue';
import { makeVInputProps } from 'vuetify/lib/components/VInput/VInput.mjs';
import { VSliderThumb } from 'vuetify/lib/components/VSlider/VSliderThumb.mjs';
import { VSliderTrack } from 'vuetify/lib/components/VSlider/VSliderTrack.mjs';
import { getOffset, useSlider, useSteps, makeSliderProps } from 'vuetify/lib/components/VSlider/slider.mjs';
import { makeFocusProps, useFocus } from 'vuetify/lib/composables/focus.mjs';
import { useProxiedModel } from 'vuetify/lib/composables/proxiedModel.mjs';
import { fit } from '@common/lib/utils/math.utils';

const props = defineProps({
  ...makeFocusProps(),
  ...makeSliderProps(),
  ...makeVInputProps(),
  modelValue: {
    type: Array as PropType<readonly (string | number)[]>,
    default: () => [0, 100]
  },
  thumbColors: {
    type: Array as PropType<string[]>,
    required: false
  },
  trackGradient: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits<{
  (event: 'update:focused', value: boolean): void;
  (event: 'update:modelValue', value: number[]): void;
  (event: 'end', value: number[]): void;
  (event: 'start', value: number[]): void;
}>();

const thumbRefs = ref<any[]>([]);

const steps = useSteps(props);

const model = useProxiedModel(props, 'modelValue', undefined, (value: number[]) => {
  if (!value?.length) return [0, 100];
  return value.map((val) => steps.roundValue(val));
}) as WritableComputedRef<number[]> & { readonly externalValue: number[] };

const onSliderStart = () => {
  emit('start', model.value);
};

const onSliderEnd = () => {
  emit('end', model.value);
};

const onSliderMove = ({ value }: { value: number }) => {
  const values = [...model.value];
  const thumbIndex = thumbRefs.value.findIndex((thumbRef) => thumbRef.$el === activeThumbRef.value);

  values[thumbIndex] = Math.max(thumbMin(thumbIndex), Math.min(thumbMax(thumbIndex), value));
  model.value = values;
};

const getActiveThumb = (e: MouseEvent | TouchEvent) => {
  if (model.value.every((value) => value === undefined)) {
    return;
  }

  const thumbs: any[] = [...thumbRefs.value];

  thumbs.sort((a: any, b: any) => {
    const aOffset = getOffset(e, a.$el, props.direction);
    const bOffset = getOffset(e, b.$el, props.direction);

    const aOffsetAbs = Math.abs(aOffset);
    const bOffsetAbs = Math.abs(bOffset);

    return aOffsetAbs < bOffsetAbs || (aOffsetAbs === bOffsetAbs && aOffset < 0) ? -1 : 1;
  });

  const activeThumb: any = thumbs.shift();

  return activeThumb.$el;
};

const computedThumbColors = computed(() => {
  const colors: (string | undefined)[] = [];

  for (let i = 0; i < model.value.length; i++) {
    colors.push(props.thumbColors?.[i] || props.thumbColor || props.color || undefined);
  }

  return colors;
});

const { activeThumbRef, hasLabels, max, min, step, mousePressed, onSliderMousedown, onSliderTouchstart, position, trackContainerRef } = useSlider({
  props,
  steps,
  onSliderStart,
  onSliderEnd,
  onSliderMove,
  getActiveThumb
});

const { isFocused, focus, blur } = useFocus(props);

const trackStart = computed(() => position(model.value[0]));
const trackEnd = computed(() => position(model.value[model.value.length - 1]));

const thumbPosition = (value: number) => position(value);

const thumbMin = (index: number) => {
  if (index === 0) return min.value;
  return model.value[index - 1] + step.value;
};

const thumbMax = (index: number) => {
  if (index === model.value.length - 1) return max.value;
  return model.value[index + 1] - step.value;
};

const onThumbModelUpdate = (index: number, value: number) => {
  const updatedModel = [...model.value];
  updatedModel[index] = value;
  model.value = updatedModel;
};

const onThumbFocus = (index: number, e: FocusEvent) => {
  focus();
  activeThumbRef.value = thumbRefs.value[index]?.$el;
};

const onThumbBlur = () => {
  blur();
  activeThumbRef.value = undefined;
};

const updateColors = () => {
  thumbRefs.value?.forEach((thumbRef, i) => {
    const el = thumbRef.$el;
    const surfaceEl = el?.querySelector('.v-slider-thumb__surface') as HTMLDivElement;
    const rippleEl = el?.querySelector('.v-slider-thumb__ripple') as HTMLDataElement;

    if (surfaceEl) {
      surfaceEl.style.color = computedThumbColors.value[i] || '';
      surfaceEl.style.caretColor = computedThumbColors.value[i] || '';
    }

    if (rippleEl) {
      rippleEl.style.color = computedThumbColors.value[i] || '';
      rippleEl.style.caretColor = computedThumbColors.value[i] || '';
    }
  });

  if (props.trackGradient) {
    const trackEl = trackContainerRef.value.$el as HTMLDivElement;
    const trackBgEl = trackEl?.querySelector('.v-slider-track__background');

    if (trackBgEl) {
      const params = ['90deg'];
      model.value.forEach((value, i) => {
        const fittedValue = fit(value, min.value, max.value, 0, 100);
        const color = computedThumbColors.value[i];
        params.push(`${color} ${fittedValue}%`);
      });

      trackBgEl.style.background = `linear-gradient(${params.join(', ')})`;
    }
  }
};

onMounted(() => {
  updateColors();
});

onUpdated(() => {
  updateColors();
});
</script>

<template>
  <v-input
    ref="inputRef"
    class="v-slider v-range-slider"
    :class="{
      'v-slider--has-labels': $slots['tick-label'] || hasLabels,
      'v-slider--focused': isFocused,
      'v-slider--pressed': mousePressed,
      'v-slider--disabled': disabled
    }"
    :focused="isFocused">
    <template #default="{ id, messagesId }">
      <div
        class="v-slider__container"
        @mousedown="onSliderMousedown"
        @touchstart.passive="onSliderTouchstart">
        <input
          v-for="(_, i) in model"
          :key="i"
          :id="`${id.value}_${i}`"
          :name="name || id.value"
          :disabled="!!disabled"
          :readonly="!!readonly"
          :value="model[i]"
          tabindex="-1" />

        <v-slider-track
          ref="trackContainerRef"
          :start="trackStart"
          :stop="trackEnd">
          <template
            v-if="$slots['tick-label']"
            #tick-label="{ tick, index }">
            <slot
              name="tick-label"
              :tick="tick"
              :index="index" />
          </template>
        </v-slider-track>

        <v-slider-thumb
          v-for="(_, i) in model"
          :key="i"
          ref="thumbRefs"
          :aria-describedby="messagesId.value"
          :model-value="model[i]"
          :min="thumbMin(i)"
          :max="thumbMax(i)"
          :ripple="ripple"
          :position="thumbPosition(model[i])"
          :focused="isFocused && activeThumbRef === thumbRefs[i].$el"
          @update:modelValue="(v: number) => onThumbModelUpdate(i, v)"
          @focus="(e: FocusEvent) => onThumbFocus(i, e)"
          @blur="onThumbBlur()">
          <template
            v-if="$slots['thumb-label']"
            #thumb-label="{ modelValue }">
            <slot
              name="thumb-label"
              :model-value="modelValue" />
          </template>
        </v-slider-thumb>
      </div>
    </template>
    <template
      v-if="!!($slots.prepend || $slots.label || label)"
      #prepend="attrs">
      <slot
        name="label"
        v-bind="{ ...attrs, label }">
        <v-label
          v-if="label"
          class="v-slider__label"
          :text="label" />
      </slot>
      <slot
        name="prepend"
        v-bind="attrs" />
    </template>
    <template
      v-if="$slots.append"
      #append="attrs">
      <slot
        name="append"
        v-bind="attrs" />
    </template>
    <template
      v-if="$slots.message"
      #message="attrs">
      <slot
        name="message"
        v-bind="attrs" />
    </template>
    <template
      v-if="$slots.details"
      #details="attrs">
      <slot
        name="details"
        v-bind="attrs" />
    </template>
  </v-input>
</template>
