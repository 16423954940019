import { FormFilter } from '@common/lib/modules/form';
import type { FormFilterSettings } from '@common/lib/modules/form';

export type LotNumberFilterSettings = FormFilterSettings;

export class LotNumberFilter extends FormFilter<LotNumberFilterSettings> {
  public override onFilter(value: any): any {
    if (!Array.isArray(value)) return value;

    value.forEach((lotNumber, i) => {
      if (!lotNumber.match(/^\d+(?:[-/]\d+(?:-\d+)?)?$/)) {
        value.splice(i, 1);
      }
    });

    return value;
  }
}
