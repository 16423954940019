import { FormValidator, isEmpty, type FormValidatorConfig, type FormValidatorSettings, type FormValidatorContext } from '@common/index';
import { ParcelSource } from '../enums/parcel-source.enum';

export type EkNumbersValidatorSettings = FormValidatorSettings;

export type EkNumbersValidatorConfig = FormValidatorConfig<EkNumbersValidatorSettings>;

export class EkNumbersValidator extends FormValidator<EkNumbersValidatorSettings> {
  constructor() {
    super();
    this._name = 'ekNumbers';
    this._settings = {
      invalidMessage: 'parcel.details.validator.ekNumbers.invalidMessage',
      needTranslate: true
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    if (context.values.source !== ParcelSource.AGRO_MAP) {
      this.reset();
      return;
    }

    this._valid = !isEmpty(value);

    if (!this._valid) {
      this.addMessage(this._settings.invalidMessage);
    }
  }
}
