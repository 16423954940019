import { FormValidator, type FormValidatorContext, type FormValidatorConfig, type FormValidatorSettings, isEmpty } from '@common/index';
import { toRaw } from 'vue';

export type ParcelAreaValidatorSettings = FormValidatorSettings;

export type ParcelAreaValidatorConfig = FormValidatorConfig<ParcelAreaValidatorSettings>;

export class ParcelAreaValidator extends FormValidator<ParcelAreaValidatorSettings> {
  private _difference = 0.1;

  constructor() {
    super();
    this._name = 'parcelArea';
    this._settings = {
      invalidMessage: 'parcel.details.validator.parcelArea.invalidMessage',
      needTranslate: true
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    if (isEmpty(value)) {
      this.reset();
      return;
    }

    const values = toRaw(context.values);
    const maxArea = value * (1 + this._difference);
    const minArea = value * (1 - this._difference);

    this._valid = !(values.area < minArea || values.area > maxArea);

    if (!this._valid) {
      this.addMessage(this.settings.invalidMessage);
    }
  }
}
