import type { RouteRecordRaw } from 'vue-router';
import core from './modules/core/router/routes';
import offer from './modules/offer/router/routes';
import parcel from './modules/parcel/router/routes';
import authRoutes from '@common/lib/modules/auth/routes/routes';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    children: [
      {
        path: '',
        component: () => import('./components/layouts/MainLayout.vue'),
        children: [...core, ...offer, ...parcel, ...authRoutes.mainRoutes]
      },
      {
        path: '',
        component: () => import('./components/layouts/LoginLayout.vue'),
        children: [...authRoutes.routes]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('./modules/core/pages/ErrorPage.vue')
  }
];

export default routes;
