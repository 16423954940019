import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    name: 'home',
    path: '/',
    component: () => import('../pages/HomePage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'videoHelp',
    path: '/videos',
    component: () => import('../pages/VideosHtmlContent.vue')
  },
  {
    name: 'helpDoc',
    path: '/help',
    component: () => import('../pages/HelpDocument.vue')
  },
  {
    name: 'tocDoc',
    path: '/toc',
    component: () => import('../pages/TocDocument.vue')
  },
  {
    name: 'test',
    path: '/test',
    component: () => import('../pages/TestPage.vue')
  },
  {
    name: 'testStepper',
    path: '/test-stepper',
    component: () => import('../pages/TestStepperFormPage.vue')
  },
  {
    name: 'testHtml',
    path: '/test-html',
    component: () => import('../pages/TestHtmlContent.vue')
  },
  {
    name: 'testTableForm',
    path: '/test-table-form',
    component: () => import('../pages/TestTableFormPage.vue')
  }
];

export default routes;
