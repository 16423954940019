import { formService } from '@common/lib/modules/form';
import {
  EkNumbersValidator,
  HarvestDateValidator,
  ParcelAreaValidator,
  PlantingDateValidator,
  YieldAmountValidator
} from '../modules/parcel/validators';
import { LotNumberFilter } from '../modules/parcel/filters/lot-number.filter';
import { ParcelFormTableBlockRequiredValidator } from '../modules/product-group/validators';
import { ParcelFilterNeedAttentionPlugin } from '../modules/parcel/plugins/parcel-filter-need-attention.plugin';
import { ParcelYieldOnlyPlugin } from '../modules/parcel/plugins/parcel-yield-only-crop.plugin';
import { ParcelFilterStatusChangePlugin } from '../modules/parcel/plugins/parcel-filter-status-change.plugin';

export const registerValidators = () => {
  formService.registerValidator('plantingDate', PlantingDateValidator);
  formService.registerValidator('harvestDate', HarvestDateValidator);
  formService.registerValidator('yieldAmount', YieldAmountValidator);
  formService.registerValidator('parcelFormTableBlockRequired', ParcelFormTableBlockRequiredValidator);
  formService.registerValidator('ekNumbers', EkNumbersValidator);
  formService.registerValidator('parcelArea', ParcelAreaValidator);
};

export const registerFilters = () => {
  formService.registerFilter('lotNumber', LotNumberFilter);
};

export const registerPlugins = () => {
  formService.registerPlugin('parcelFilterNeedAttention', ParcelFilterNeedAttentionPlugin);
  formService.registerPlugin('parcelFilterStatusChange', ParcelFilterStatusChangePlugin);
  formService.registerPlugin('parcelYieldOnlyCrop', ParcelYieldOnlyPlugin);
};

export const initForms = () => {
  registerValidators();
  registerFilters();
  registerPlugins();
};
