import { FormValidator, type FormValidatorConfig, type FormValidatorSettings, isEmpty } from '@common/index';

export interface ParcelFormTableBlockRequiredValidatorSettings extends FormValidatorSettings {
  rowSelectedFn: () => boolean;
  orders: any[];
  dateMustBeAfterLastOrderMessage?: string;
}

export type ParcelFormTableBlockRequiredValidatorConfig = FormValidatorConfig<ParcelFormTableBlockRequiredValidatorSettings>;

export class ParcelFormTableBlockRequiredValidator extends FormValidator<ParcelFormTableBlockRequiredValidatorSettings> {
  constructor() {
    super();
    this._name = 'parcelFormTableBlockRequired';
    this._settings = {
      invalidMessage: 'validator.parcelFormTableBlockRequired.invalidMessage',
      dateMustBeAfterLastOrderMessage: 'validator.parcelFormTableBlockRequired.dateMustBeAfterLastOrderMessage',
      needTranslate: true,
      rowSelectedFn: () => false,
      orders: []
    };
  }

  public override async onValidate(value: any) {
    const selected = this.settings.rowSelectedFn();

    if (!selected) {
      this._valid = true;
      return;
    }

    if (isEmpty(value)) {
      this._valid = false;
      this.addMessage(this.settings.invalidMessage);
      return;
    }

    if (this.settings.orders.length) {
      this.settings.orders.sort((a: any, b: any) => {
        const bDate = new Date(b.fertilizationDate1);
        const aDate = new Date(a.fertilizationDate1);
        return bDate.getTime() - aDate.getTime();
      });

      const lastOrderDate = new Date(this.settings.orders[0].fertilizationDate1);
      const valueDate = new Date(value);

      if (valueDate.getTime() <= lastOrderDate.getTime()) {
        this._valid = false;
        this.addMessage(this.settings.dateMustBeAfterLastOrderMessage as string, 'error', {
          lastDate: this.settings.orders[0].fertilizationDate1
        });
        return;
      }
    }

    this._valid = true;
  }
}
