import type { Form, FormElement, SelectableFormElement } from '@common/lib/modules/form';
import { FormElementPlugin } from '@common/lib/modules/form';
import { ParcelStatus } from '../enums';

export class ParcelFilterStatusChangePlugin extends FormElementPlugin {
  public constructor() {
    super();
    this._name = 'parcelFilterStatusChange';
  }

  public override onInput(element: FormElement<any>, value: ParcelStatus[]) {
    const form = element.form as Form;
    const allAttentionNeededCheckbox = form.getChild('needAttention') as SelectableFormElement;

    const neededStatuses = [
      ParcelStatus.EDITING,
      ParcelStatus.WAITING_FOR_FINALIZATION,
      ParcelStatus.AWAITING_YIELD_DATA,
      ParcelStatus.AWAITING_PLANNED_CROP_DATA
    ];

    if (!value || !value.length || neededStatuses.length !== value.length || !neededStatuses.every((status) => value.includes(status))) {
      allAttentionNeededCheckbox.value = false;
    } else {
      allAttentionNeededCheckbox.value = true;
    }
  }
}
