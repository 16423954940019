import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    name: 'offers',
    path: '/offers',
    component: () => import('../pages/OffersPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'offerDetails',
    path: '/offers/:offerId',
    component: () => import('../pages/OffersDetailsPage.vue'),
    props: true,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: 'offerDetailsBaseDataTab',
        path: '',
        component: () => import('../pages/OfferDetailsBaseDataPage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'offerDetailsParcelListTab',
        path: 'parcel-list',
        component: () => import('../pages/OfferDetailsParcelListPage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'offerDetailsAvailableOrderListTab',
        path: 'orderable-product-list',
        component: () => import('../pages/OfferDetailsAvailableOrderListPage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'offerDetailsOrderListTab',
        path: 'ordered-product-list',
        component: () => import('../pages/OfferDetailsOrderListPage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
];

export default routes;
