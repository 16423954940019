import type { SelectableFormElement } from '@common/lib/modules/form';
import { FormValidator, type FormValidatorContext, type FormValidatorConfig, type FormValidatorSettings, isEmpty } from '@common/index';

export type YieldAmountValidatorSettings = FormValidatorSettings;

export type YieldAmountValidatorConfig = FormValidatorConfig<YieldAmountValidatorSettings>;

export class YieldAmountValidator extends FormValidator<YieldAmountValidatorSettings> {
  constructor() {
    super();
    this._name = 'yieldAmount';
    this._settings = {
      invalidMessage: 'parcel.details.validator.yieldAmount.invalidMessage',
      needTranslate: true
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    if (isEmpty(value)) {
      this.reset();
      return;
    }

    const cropField = context.element.parent?.children.find((field) => field.name === 'crop') as SelectableFormElement;
    const currentCrop = cropField?.items.find((crop) => crop.code === cropField.value);
    if (currentCrop) {
      if (!((!currentCrop.minYield || currentCrop.minYield <= value) && (!currentCrop.maxYield || currentCrop.maxYield >= value))) {
        this.addMessage(this._settings.invalidMessage, 'warning', {
          min: currentCrop.minYield,
          max: currentCrop.maxYield
        });
      }
    }
  }
}
